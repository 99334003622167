import Vue from "vue";
import { API } from "@/common-http.js";
import CreateRoomForm from "@/components/CreateRoomForm.vue";
import LogInForm from "@/components/LogInForm.vue";
import NavUser from "@/components/navbar/NavUser.vue";
import NavCreateRoom from "@/components/navbar/NavCreateRoom.vue";
import Notifier from "@/components/Notifier.vue";
import { loadLanguageAsync } from "@/i18n";
import { createRoomHelper, createRoomState } from "@/util/roomcreator";
export default Vue.extend({
    name: "app",
    components: {
        CreateRoomForm,
        LogInForm,
        NavUser,
        NavCreateRoom,
        Notifier,
    },
    data() {
        return {
            createRoomState,
            showCreateRoomForm: false,
            showLogin: false,
            drawer: false,
            locales: [
                {
                    text: "🇺🇸",
                    value: "en",
                },
                {
                    text: "🇷🇺",
                    value: "ru",
                },
            ],
        };
    },
    methods: {
        logout() {
            API.post("/user/logout").then(res => {
                if (res.data.success) {
                    this.$store.commit("LOGOUT");
                }
            });
        },
        async setLocale(locale) {
            await loadLanguageAsync(locale);
            this.$store.commit("settings/UPDATE", { locale });
        },
        cancelRoom() {
            createRoomState.value.cancelledRoomCreation = true;
            createRoomState.value.isLoadingCreateRoom = false;
        },
        async createTempRoom() {
            await createRoomHelper(this.$store);
        },
    },
    async created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === "misc/ROOM_CREATED") {
                try {
                    // @ts-expect-error because vue router doesn't quite work with ts like this and im feeling lazy.
                    this.$router.push(`/room/${mutation.payload.name}`);
                }
                catch (e) {
                    if (e.name !== "NavigationDuplicated") {
                        throw e;
                    }
                }
            }
        });
        document.addEventListener("fullscreenchange", () => {
            var _a, _b;
            if (document.fullscreenElement) {
                this.$store.state.fullscreen = true;
                (_a = document.querySelector("html")) === null || _a === void 0 ? void 0 : _a.classList.add("scrollbarBeGone");
            }
            else {
                this.$store.state.fullscreen = false;
                (_b = document.querySelector("html")) === null || _b === void 0 ? void 0 : _b.classList.remove("scrollbarBeGone");
            }
        });
        await this.$store.dispatch("settings/load");
        await this.$store.dispatch("getNewToken");
        await this.setLocale(this.$store.state.settings.locale);
        // ask the server if we are logged in or not, and update the client to reflect that status.
        let resp = await API.get("/user");
        if (resp.data.loggedIn) {
            let user = resp.data;
            delete user.loggedIn;
            this.$store.commit("LOGIN", user);
        }
    },
});
