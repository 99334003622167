import vuetify from "@/plugins/vuetify";
export var RoomLayoutMode;
(function (RoomLayoutMode) {
    RoomLayoutMode["default"] = "default";
    RoomLayoutMode["theater"] = "theater";
})(RoomLayoutMode || (RoomLayoutMode = {}));
export var Theme;
(function (Theme) {
    Theme["light"] = "light";
    Theme["dark"] = "dark";
})(Theme || (Theme = {}));
export const settingsModule = {
    namespaced: true,
    state: {
        volume: 100,
        locale: "ru",
        roomLayout: RoomLayoutMode.theater,
        theme: Theme.dark,
    },
    mutations: {
        UPDATE(state, settings) {
            Object.assign(state, settings);
            localStorage.setItem("settings", JSON.stringify(state));
            // apply some global settings
            if (settings.theme !== undefined) {
                // this is set up so that if the value of theme is invalid,
                // it will default back to the dark theme instead of the light one.
                switch (settings.theme) {
                    case Theme.dark:
                        vuetify.framework.theme.dark = true;
                        break;
                    case Theme.light:
                        vuetify.framework.theme.dark = false;
                        break;
                    default:
                        vuetify.framework.theme.dark = true;
                        break;
                }
            }
        },
    },
    actions: {
        load(context) {
            var _a;
            let loaded = JSON.parse((_a = localStorage.getItem("settings")) !== null && _a !== void 0 ? _a : "{}");
            context.commit("UPDATE", loaded);
        },
    },
};
